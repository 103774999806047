import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import notFound from "../images/internet_404_page_not_found.png";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <img
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src={notFound}
        />
      </div>
    </Layout>
  );
}

export default NotFoundPage;
